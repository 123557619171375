<template>
<div>


<div class="container-fluid  " style="margin-top: 100px">
     <div class="row flex-nowrap min-vh-100">
       <div class="col-auto  sticky-top "  style="width: 15%">
         <div class="d-flex flex-sm-column flex-row flex-nowrap  align-items-start sticky-top">
           <a href="/" class="d-flex align-items-start pb-3 mb-md-0 me-md-auto text-white text-decoration-none">

             <span class="fs-5 d-none d-sm-inline"></span>
           </a>
           <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-start align-items-sm-start" id="menu">
             <li class="nav-item">
               <a href="#" class="nav-link align-items-start px-1">
                 <i class="fs-4 bi-house"></i> <span class="ms-1 d-none d-sm-inline">Home {{userRole}}</span>
               </a>
             </li>
             <li v-if="userRole === 'admin'" class="nav-item">
               <a href="#" class="nav-link align-items-start px-1">
                 <i class="fs-4 bi-house"></i> <span class="ms-1 d-none d-sm-inline" >WP - System View</span>
               </a>
             </li>
             <li>
               <a href="#submenu1" data-bs-toggle="collapse" class="nav-link px-0 align-middle">
                 <i class="fs-4 bi-clipboard-plus"></i> <span class="ms-1 d-none d-sm-inline"><strong>Clip-Board</strong></span> </a>
               <ul class="collapse.show  nav flex-column ms-1" id="submenu1" data-bs-parent="#menu">
                 <li class="w-100 text-wrap">
                   <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Commodities</span> </a>
                   <ul v-if="$store.state.clipboard.commodityID != ''"  >
                     <li style="font-size: small; color:orangered">{{$store.state.clipboard.commodityName}}</li>
                   </ul>
                 </li>
                 <li class="w-100 text-wrap">
                   <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Material</span> </a>
                   <ul v-if="$store.state.clipboard.materialID != ''"  >
                     <li style="font-size: small; color:orangered">{{$store.state.clipboard.materialName}}</li>
                   </ul>
                 </li>
                 <li class="w-100 text-wrap">
                   <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Equipment</span> </a>
                   <ul v-if="$store.state.clipboard.equipmentID != ''"  >
                     <li style="font-size: xx-small; color:orangered">{{$store.state.clipboard.equipmentName}}</li>
                   </ul>
                 </li>
                 <li>
                   <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Processes</span> </a>
                   <ul v-if="$store.state.clipboard.processID != ''"  >
                     <li style="font-size: small; color:orangered">{{$store.state.clipboard.processName}}</li>
                   </ul>
                 </li>
                 <li>
                   <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Assembly-Processes</span> </a>
                   <ul v-if="$store.state.clipboard.assyprocessID != ''"  >
                     <li style="font-size: small; color:orangered">{{$store.state.clipboard.assyprocessName}}</li>
                   </ul>
                 </li>
                 <li>
                   <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Parts</span> </a>
                   <ul v-if="$store.state.clipboard.partID != ''"  >
                     <li style="font-size: small; color:orangered">{{$store.state.clipboard.partName}}</li>
                   </ul>
                 </li>
                 <li>
               </ul>
             </li>
             <li>
               <a href="#" class="nav-link px-0 align-items-start px-1">
                 <i class="fs-4 bi-table" ></i> <span class="ms-1 d-none d-sm-inline"><strong>Informations</strong></span></a>
             </li>

             <li>
               <a href="#submenu2" data-bs-toggle="collapse" class="nav-link px-0 align-left px-1">
                 <i class="fs-4 bi-bookmark"></i> <span class="ms-1 d-none d-sm-inline"><strong>Favorites</strong></span> </a>
               <ul class="collapse nav flex-column ms-1" id="submenu2" data-bs-parent="#menu">
                 <li class="w-100">
                   <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Equipment</span></a>
                 </li>
                 <li>
                   <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Processes</span></a>
                 </li>
                 <li>
                   <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Parts</span></a>
                 </li>
                 <li>
                   <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Materials</span></a>
                 </li>
               </ul>
             </li>
             <li>
               <a href="#" class="nav-link px-0 align-items-start px-1">
                 <i class="fs-4 bi-people"></i> <span class="ms-1 d-none d-sm-inline"><strong>User Profil</strong></span> </a>
             </li>
           </ul>
           <hr>
           <div class="dropdown pb-4">
             <a href="#" class="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">

               <div v-for="(value, index) in namespace" v-bind:key="value">
                 <div v-if="index==='https://www.sustamizer.net/picture'"><img
                     v-bind:src="value"
                     alt="User's profile picture"
                     class="rounded-circle img-fluid profile-picture" width="50" v-bind:key="value"
                 /></div>
               </div>
               <span class="d-none d-sm-inline mx-1">{{ $auth.user.name }}</span>
             </a>
             <ul class="dropdown-menu dropdown-menu-dark text-small shadow">
               <li><router-link to="/profile" class="dropdown-item" />Profile</li>
               <li><a class="dropdown-item" href="#">Logout</a></li>
               <li>
                 <hr class="dropdown-divider">
               </li>
               <li><a class="dropdown-item" href="#">Sign out</a></li>
             </ul>
           </div>
         </div>
       </div>
       <div class="col py-3">
         <h5>Project No.: {{projectNo}}__Workpackage No.:  {{wpID}}__Workpackage: {{wpName}} </h5>
         <WorkpackageSCA_Header  v-bind:namespace="namespace" />

       </div>
     </div>
 </div>

</div>
</template>

<script>

import WorkpackageSCA_Header from "@/components/WorkpackageSCA_Header";

const axios = require('axios').default;

// axios.<method> will now provide autocomplete and parameter typings

export default {
  name: "WorkpackageSCA, WorkpackageSCA_Header",
  components: {WorkpackageSCA_Header},
  data() {
    return {
      projectno: null,
      wpID: null,
      wpName: null,
      userRole:'',
      namespace : (this.$parent.$auth.user),
      trigger: 0,
      sustamizerVersion: '',
      version: 'V01.0153',
  };
  },


  methods: {
    // CHECK FOR UPDATES
    triggerUpdate: function () {
      this.trigger++;
        axios.post('/src/php/sustamizer_collab_updatecheck.php', {
          action: 'checkupdate'
        }).then((response) => {
          if (this.version != response.data[0].sustamizerVersion) {
           this.$toasted.show("Attention, new sustamizer Version available, it is recommended to reload the application!", {
              theme: "bubble",
              position: "top-right",
              fullWidth: true,
              duration: 5000,
              icon: 'info',
                  action : {
                    text : 'Ok',
                    onClick : (e, toastObject) => {
                      toastObject.goAway(0);
                    }
                  },
            }
            );
          }
            this.trigger = 0;
        });

    }
  },
  mounted: function () {
    window.scrollTo(0,0)
    this.projectno = this.$route.query.projectno
    this.wpID = this.$route.query.wpID
    this.wpName = this.$route.query.wpName

    this.triggerUpdate();
    this.timer = setInterval(this.triggerUpdate, 200000);
    this.userRole = 'admin'

  }



  }
</script>

<style scoped>

.col-sm-auto
{
  background-color: #5A5C4F;
  color: #E7E9DF;
}

.d-flex
{
  background-color: #5A5C4F;
  color: #E7E9DF;
}


.nav .nav-item .nav-link
{
  color: #E7E9DF;
}

li a
{
  color: #E7E9DF;
}



</style>