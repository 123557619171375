import Vue from "vue";
import Router from "vue-router";
import Home from "../views/Home.vue";
import Profile from "../views/Profile.vue";
import Project from "../views/Project.vue";
import ProjectLab from "../views/ProjectLab.vue";
import Workpackages from "../views/Workpackages.vue";
import WorkpackageSCA from "../views/WorkpackageSCA.vue";
import { authGuard } from "../auth";
import Toasted from 'vue-toasted';
import SystemView from "@/views/SystemView";
import { vfmPlugin } from 'vue-final-modal';

Vue.use(vfmPlugin);
Vue.use(Router);
Vue.use(Toasted, {
  iconPack : 'material' // set your iconPack, defaults to material. material|fontawesome|custom-class
} );

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      beforeEnter: authGuard
    },
    {
      path: "/profile",
      name: "profile",
      component: Profile,
      beforeEnter: authGuard
    },
    {
      path: "/project",
      name: "project",
      component: Project,
      props: true,
      //props(route) {
      //  return {wpName: route.query.wpName}
      //},
      beforeEnter: authGuard
    },
    {
      path: "/projectlab",
      name: "projectlab",
      component: ProjectLab,
      props: true,
      //props(route) {
      //  return {wpName: route.query.wpName}
      //},
      beforeEnter: authGuard
    },
    {
      path: "/systemview",
      name: "systemview",
      component: SystemView,
      props: true,
      //props(route) {
      //  return {wpName: route.query.wpName}
      //},
      beforeEnter: authGuard
    },
    {
      path: "/workpackages",
      name: "workpackages",
      component: Workpackages,
      props: true,
      beforeEnter: authGuard
    },
    {
      path: "/workpackageSCA",
      name: "workpackageSCA",
      component: WorkpackageSCA,
      props: true,
      beforeEnter: authGuard
    }
  ]
});

export default router;
