<template>

<div  class="w-100">
  <h5 style="color:blue">CO2 Emisson Anlaysis </h5>

  <div class="row justify-content-start" >
    <div class="col-2">
  <inner-image-zoom v-bind:src='this.wpImgPath1'  v-bind:zoomSrc='this.wpImgPath2'  v-bind:width='this.imgWidth'/>
     </div>
    <div class="col-8">
      Product Production Process Analysis

<!--      <form>
        <div class="row mb-3">
          <label for="inputEmail3" class="col-sm-2 col-form-label">Description</label>
          <div class="col-sm-10">
            <input type="email" class="form-control" id="inputEmail3">
          </div>
        </div>
        <div class="row mb-3">
          <label for="inputPassword3" class="col-sm-2 col-form-label">Assessment comment</label>
          <div class="col-sm-10">
            <input type="password" class="form-control" id="inputPassword3">
          </div>
        </div>
        <fieldset class="row mb-3">
          <legend class="col-form-label col-sm-2 pt-0">Radios</legend>
          <div class="col-sm-10">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios1" value="option1" checked>
              <label class="form-check-label" for="gridRadios1">
                Idea generation active
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios2" value="option2">
              <label class="form-check-label" for="gridRadios2">
                Benchmark comparison active
              </label>
            </div>
            <div class="form-check disabled">
              <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios3" value="option3" disabled>
              <label class="form-check-label" for="gridRadios3">
                CO2 calculation active
              </label>
            </div>
          </div>
        </fieldset>
        <div class="row mb-3">
          <div class="col-sm-10 offset-sm-2">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="gridCheck1">
              <label class="form-check-label" for="gridCheck1">
                Dynamic reporting
              </label>
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-primary">Sign in</button>
      </form>-->


    </div>
    <div class="col-2 align-items-end" >
<!--      <div> <button class="btn btn-secondary"  @click="forceUpdate" >Refresh</button></div>-->
      <div> <button class="btn btn-warning"  >Current CO2e Emissions per Product <strong>{{wpCostSum}}kgCO2</strong></button></div>
     <div v-if="wpStatus=='working'" > <router-link :to="{path: '/workpackages',  query:{wpID: this.wpID, wpName: this.wpName, projectNo: this.projectNo, wpHash:this.wpHash, wpStatus:'qualitycheck' }}" class="btn btn-success btn-block">Q-CHECK</router-link>
    </div>
      <div v-if="wpStatus=='qualitycheck' && userRole == 'qamanager'"> <router-link :to="{path: '/workpackages',  query:{wpID: this.wpID, projectNo: this.projectNo, wpHash:this.wpHash, wpStatus:'released' }}" class="btn btn-success">Release</router-link>
      </div>
      <div v-if="wpStatus=='qualitycheck' && userRole == 'qamanager'"> <router-link :to="{path: '/workpackages',  query:{wpID: this.wpID, projectNo: this.projectNo, wpHash:this.wpHash, wpStatus:'qsfailed' }}" class="btn btn-danger">QS Failed</router-link>
      </div>
      <div  style="padding-top:15px"> <router-link :to="{path: '/workpackages',  query:{wpID: this.wpID, projectNo: this.projectNo, wpHash:this.wpHash, wpStatus:'working' }}" class="btn btn-primary">WP Overview</router-link>
    </div>
  </div>
</div>

  <div  class="w-100" id="WorkpackageSCA_Header">
    <br />
    <Assemblies v-bind:parentassyID="parentassyID" v-bind:userRole="userRole"  v-bind:wpStatus="wpStatus" v-bind:namespace="namespace"/>
    <Parts v-bind:subassyID="subassyID"  v-bind:userRole="userRole" v-bind:wpStatus="wpStatus" v-bind:namespace="namespace"/>
    <Assemblyproc v-bind:subassyID="subassyID" v-bind:assyRoot="assyRoot"  v-bind:wpStatus="wpStatus" v-bind:namespace="namespace"/>

 </div>




</div>
  </template>

<script>

 const axios = require('axios').default;

  import Parts from "@/components/Parts";
  import Assemblyproc from "@/components/Assembly_Proc";
  import Assemblies from "@/components/Assembly_Component";
   // Image Zoom
  import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';
  import InnerImageZoom from 'vue-inner-image-zoom';
  // Popovers



  export  default {
    name: "WorkapackageSCA_Header",
    props: {
      namespace: Array,

    },


    components: {Assemblies, Assemblyproc, Parts,InnerImageZoom},



    data: function () {
      return {
        parentassyID:'ROOT', subassyID:'NONE', assyRoot:'ROOT', wpImgPath1:'', wpImgPath2:'', wpStatus:  this.$route.query.wpStatus,
        wpID: this.$route.query.wpID, wpHash:  this.$route.query.wpHash, projectNo: this.$route.query.projectNo, imgWidth: '200px',
        userRole : 'admin', wpName: this.$route.query.wpName, mouseY: 0
      };
    },


    mounted: function () {
      {
        // GET WP IMAGES
        let self = this

        axios.post('/src/php/sustamizer_collab_manage_workpackages.php', {
          action: 'getWPIMG',
          wpID: this.$route.query.wpID,
        }).then(function (response) {
          // self.wpImgPath1 = "/src/assets/wpimg/quertraeger.png"
          self.wpImgPath1 = response.data.wpImgPath1;
          // same path for the moment
          self.wpImgPath2 = response.data.wpImgPath1;

        });
        axios.post('/src/php/sustamizer_collab_ownequipmentlist.php', {
          action: 'getEQList',
          ceID: this.$parent.$auth.user.sub
        }).then(function (response) {
          let ownEQUList = '';
          for (let i = 0; i < response.length; i++) {
            ownEQUList = ownEQUList + response.data.equipmentName + ', ' +
                response.data.equipmentDesc + ',  Invest:' +
                response.data.equipmentInvestment + ', ' +
                response.data.UPPID;


            this.$store.commit('addOwnEquip', {
              UPPID: response.data.UPPID,
              equipDes: ownEQUList
            });
          }

        });
      }
    },
    computed: {
      wpCostSum () {
        let wpCostSumVal= 0
        if (this.$store.state.wpSum.length > 1) {
        for (let i = 0; i < this.$store.state.wpSum.length; i++) {

          if (this.$store.state.wpSum[i].wpID == this.$route.query.wpID) {
            wpCostSumVal = wpCostSumVal+ this.$store.state.wpSum[i].wpTotalCostSum
          } else {
            wpCostSumVal = 0.0
          }
        }
        }
        else {
          wpCostSumVal = 0.0
        }
      /*  wpCostSumVal = wpCostSumVal + this.trigger
        wpCostSumVal = wpCostSumVal - this.trigger*/
        wpCostSumVal = Math.round(wpCostSumVal * 100) / 100
      // ATTENTION SUSTIMIZER FACTOR = 3.2
        return (wpCostSumVal*3.2)
      }
    }

  }
</script>

<style scoped>
table.table-fit {
  width: auto !important;
  table-layout: auto !important;
}
table.table-fit thead th, table.table-fit tfoot th {
  width: auto !important;
}
table.table-fit tbody td, table.table-fit tfoot td {
  width: auto !important;
}
button
{
  margin-bottom: 10px;
}



</style>